/* .App {
  font-size: calc(10px + 2vmin);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.root {
  overflow: hidden;
  background-color: #f3f4f5;
  min-height: 93vh;
}

.lastcard {
  padding-bottom: 10px;
}

.big-card-start {
  background-color: #f5f5f5;
  color: black;
  height: auto;
  width: 100%;
  /* padding-left: 20px;
  padding-right: 20px; */
  padding-top: 50px;
  padding-bottom: 20px;
}

.big-card-clean {
  background-color: #f5f5f5;
  color: white;
  height: auto;
  width: 100%;
  /* padding-left: 20px;
  padding-right: 20px; */
  padding-top: 20px;
  padding-bottom: 20px;
}

.big-card-green {
  background-color: #00b885;
  color: white;
  height: auto;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.big-card-footer {
  /* background-color: #c4c4c4;
  color: white;
  height: px; 
  width: 100%; */
  /* padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center; */
}

.card {
  margin: 0 2% !important;
}

.infected {
  border-bottom: 10px solid rgba(0, 0, 255, 0.5);
}
.recovered {
  border-bottom: 10px solid rgba(0, 255, 0, 0.5);
}
.deaths {
  border-bottom: 10px solid rgba(255, 0, 0, 0.5);
}

.tables {
  flex-direction: row;
}
.tables2 {
  flex-direction: column;
}
@media only screen and (max-width: 770px) {
  .card {
    margin: 2% 0 !important;
  }
}
